import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass } from '@angular/common';
import { Component, input, TemplateRef, ViewChild } from '@angular/core';
import * as fromSeriousSystem from '../atoms';

export interface DropdownMenuOption {
  label: string;
  icon?: fromSeriousSystem.UIBasicIconsType;
  action?: (() => void) | undefined;
  disabled?: boolean;
  error?: boolean;
  separator?: boolean;
  options?: DropdownMenuOption[];
}

@Component({
  selector: 'sd-dropdown-menu',
  imports: [
    NgClass,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseArrowIconDirective,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
  ],
  template: `
    <ng-template #menu>
      <div
        cdkMenu
        class="flex flex-col w-fit bg-shades-white shadow-md rounded-md border border-neutral-200"
      >
        @for (option of options(); track option.label) {
        <button
          cdkMenuItem
          class="
            flex flex-row gap-2 items-end
            p-4 h-14 tablet-landscape:h-12 tablet-landscape:w-48 w-full
            typo-p3
            active:font-semibold
            disabled:opacity-50 disabled:cursor-not-allowed disabled:text-neutral-700 disabled:hover:bg-transparent disabled:active:font-normal
            [&>svg]:size-5
          "
          [ngClass]="{
            'hover:bg-primary-500/5 active:bg-primary-500/10 active:text-neutral-700 ': !option.error,
            'text-error-600 hover:bg-error-50 active:bg-error-50 active:text-error-600': option.error,
            'border-t border-neutral-200': option.separator,
            'rounded-md': !option.separator,
          }"
          [disabled]="option.disabled"
          (click)="option.action ? option.action() : null"
          [cdkMenuTriggerFor]="
            option.options?.length ? menuComponent.menu : null
          "
        >
          @if (option.icon) {
          <svg
            [sdUseUIBasicIcon]="option.icon!"
            [class.text-neutral-700]="!option.error"
            [class.text-error-500]="option.error"
          />
          }
          <span
            class="max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
            >{{ option.label }}</span
          >
          @if (option.options?.length) {
          <svg
            sdUseArrowIcon="chevron-small-right"
            class="size-5 ml-auto my-auto"
          />
          }
        </button>
        <sd-dropdown-menu
          #menuComponent
          [options]="option.options!"
        ></sd-dropdown-menu>
        }
      </div>
    </ng-template>
  `,
  styles: [``],
})
export class DropdownMenuComponent {
  public readonly options = input.required<DropdownMenuOption[]>();

  @ViewChild('menu', { static: true }) menu!: TemplateRef<unknown>;
}
