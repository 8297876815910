export enum AssistantCustomIconsEnum {
  'default' = 'default',
  'default-filled' = 'default-filled',
  'document-filled' = 'document-filled',
  'document' = 'document',
  'folder' = 'folder',
  'folder-filled' = 'folder-filled',
  'file-check' = 'file-check',
  'file-check-filled' = 'file-check-filled',
  'file-edit' = 'file-edit',
  'file-edit-filled' = 'file-edit-filled',
  'list' = 'list',
  'list-filled' = 'list-filled',
  'question' = 'question',
  'question-filled' = 'question-filled',
  'comment' = 'comment',
  'comment-filled' = 'comment-filled',
  'info' = 'info',
  'info-filled' = 'info-filled',
  'bill' = 'bill',
  'bill-filled' = 'bill-filled',
  'briefcase' = 'briefcase',
  'briefcase-filled' = 'briefcase-filled',
  'search' = 'search',
  'search-filled' = 'search-filled',
  'user-time-filled' = 'user-time-filled',
  'user-time' = 'user-time',
  'user-check' = 'user-check',
  'user-check-filled' = 'user-check-filled',
  'handshake' = 'handshake',
  'handshake-filled' = 'handshake-filled',
  'bulb' = 'bulb',
  'bulb-filled' = 'bulb-filled',
  'clock' = 'clock',
  'clock-filled' = 'clock-filled',
  'calendar-edit' = 'calendar-edit',
  'calendar-edit-filled' = 'calendar-edit-filled',
  'shield' = 'shield',
  'shield-filled' = 'shield-filled',
  'law' = 'law',
  'law-filled' = 'law-filled',
  'monitor' = 'monitor',
  'monitor-filled' = 'monitor-filled',
  'trend' = 'trend',
  'trend-filled' = 'trend-filled',
  'certificate' = 'certificate',
  'certificate-filled' = 'certificate-filled',
  'target' = 'target',
  'target-filled' = 'target-filled',
}

export type AssistantCustomIconsType = `${AssistantCustomIconsEnum}`;
export const AssistantCustomIconsList = Object.values(AssistantCustomIconsEnum);
