import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';

import * as fromHelpers from '../helpers';

export enum NavSectionSizeVariant {
  collapsed = 'collapsed',
  expanded = 'expanded',
}

export enum NavSectionStatusVariant {
  default = 'default',
  selected = 'selected',
  filled = 'filled',
}

export const navSectionVariants = cva(
  fromHelpers.cn(
    'flex items-center justify-center hover:cursor-pointer rounded hover:bg-transparency-white-16 [&>svg]:size-6 typo-p2'
  ),
  {
    variants: {
      status: {
        [`${NavSectionStatusVariant.selected}`]:
          'bg-transparency-white-16 text-shades-white',
        [`${NavSectionStatusVariant.default}`]: 'text-transparency-white-60',
        [`${NavSectionStatusVariant.filled}`]: 'text-shades-white',
      },
      size: {
        [`${NavSectionSizeVariant.collapsed}`]: 'w-10 h-10 justify-center',
        [`${NavSectionSizeVariant.expanded}`]:
          'w-full h-14 justify-start pl-4 *:mr-4',
      },
    },
    defaultVariants: {
      status: NavSectionStatusVariant.default,
      size: NavSectionSizeVariant.collapsed,
    },
  }
);
export type NavSectionVariants = VariantProps<typeof navSectionVariants>;

@Directive({
  selector: '[sdNavSection]',
  standalone: true,
  host: {
    '[class]': 'className()',
  },
})
export class NavSectionDirective {
  readonly size = input<NavSectionVariants['size']>(
    NavSectionSizeVariant.collapsed
  );
  readonly status = input<NavSectionVariants['status']>(
    NavSectionStatusVariant.default
  );

  protected readonly className = computed(() =>
    fromHelpers.cn(
      navSectionVariants({
        size: this.size(),
        status: this.status(),
      })
    )
  );
}
