import { Directive, input } from '@angular/core';

import { AssistantCustomIconsType } from './assistant-customs';
import { BaseIconDirective } from './base-icon.directive';

@Directive({
  selector: 'svg[sdUseAssistantCustomIcon]',
  standalone: true,
})
export class UseAssistantCustomIconDirective extends BaseIconDirective {
  public readonly sdUseAssistantCustomIcon =
    input.required<AssistantCustomIconsType>();
  readonly BASE_PATH = '/assets/assistant-customs.sprites.svg';

  public get icon() {
    return this.sdUseAssistantCustomIcon();
  }
}
