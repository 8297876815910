import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Renderer2,
  effect,
  inject,
} from '@angular/core';

@Directive()
export abstract class BaseIconDirective implements AfterViewInit {
  protected readonly elementRef = inject<ElementRef<SVGElement>>(ElementRef);
  protected readonly renderer = inject(Renderer2);
  protected readonly document = inject<Document>(DOCUMENT);

  // Reference to <use> element to update its attribute in case of change
  private useElement: SVGUseElement | null = null;

  constructor() {
    // An effect to monitor icon changes
    effect(() => {
      if (this.icon && this.useElement) {
        this.updateUseElement();
      }
    });
  }

  ngAfterViewInit(): void {
    // Create <use> element and initialize basic attributes
    this.useElement = this.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'use'
    );

    this.elementRef.nativeElement.setAttribute('width', '100%');
    this.elementRef.nativeElement.setAttribute('height', '100%');

    this.updateUseElement();

    this.renderer.appendChild(this.elementRef.nativeElement, this.useElement);
  }

  private updateUseElement(): void {
    if (this.useElement) {
      const newHref = `${this.BASE_PATH}#${this.icon}`;

      this.useElement.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'href',
        newHref
      );
    }
  }

  protected abstract BASE_PATH: string;
  protected abstract icon: string;
}
