export enum LogoEnum {
  'logo-full' = 'logo-full',
  'logo-full-filled' = 'logo-full-filled',
  'logo-minimal' = 'logo-minimal',
  'logo-minimal-filled' = 'logo-minimal-filled',
  'logo-dark-surface' = 'logo-dark-surface',
}

export type LogoType = `${LogoEnum}`;
export const LogoList = Object.keys(LogoEnum) as LogoEnum[];
