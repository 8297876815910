export enum UIBasicIconsEnum {
  'plus' = 'plus',
  'search' = 'search',
  'voice' = 'voice',
  'menu' = 'menu',
  'close' = 'close',
  'more-horizontal' = 'more-horizontal',
  'more-vertical' = 'more-vertical',
  'warning-filled' = 'warning-filled',
  'add-document' = 'add-document',
  'add-folder' = 'add-folder',
  'star' = 'star',
  'star-filled' = 'star-filled',
  'folder' = 'folder',
  'edit' = 'edit',
  'archive' = 'archive',
  'delete' = 'delete',
  'clock' = 'clock',
  'info' = 'info',
  'zoom-out' = 'zoom-out',
  'zoom-in' = 'zoom-in',
  'success-filled' = 'success-filled',
  'error-filled' = 'error-filled',
  'bubbles' = 'bubbles',
  'trend-up' = 'trend-up',
  'refresh' = 'refresh',
  'save' = 'save',
  'attach' = 'attach',
  'bulb' = 'bulb',
  'text-short' = 'text-short',
  'text-long' = 'text-long',
  'translation' = 'translation',
  'help' = 'help',
  'copy' = 'copy',
  'thumbs-up' = 'thumbs-up',
  'thumbs-down' = 'thumbs-down',
  'at' = 'at',
  'typing' = 'typing',
  'text' = 'text',
  'audio-on' = 'audio-on',
  'audio-off' = 'audio-off',
  'thumbs-up-filled' = 'thumbs-up-filled',
  'thumbs-down-filled' = 'thumbs-down-filled',
  'stop-filled' = 'stop-filled',
  'settings' = 'settings',
  'filters' = 'filters',
  'mic' = 'mic',
  'check' = 'check',
  'fit-width' = 'fit-width',
  'fit-page' = 'fit-page',
  'quote' = 'quote',
  'file-export' = 'file-export',
}

export type UIBasicIconsType = `${UIBasicIconsEnum}`;
export const UIBasicIconsList = Object.values(UIBasicIconsEnum);
