import {
  AfterViewInit,
  computed,
  Directive,
  effect,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  Renderer2,
  signal,
} from '@angular/core';

@Directive({
  selector: '[sdTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
  tooltipText = input<string>('sdTooltip', { alias: 'sdTooltip' });
  sdTooltipDirection = input<'top' | 'right'>('top');
  sdTooltipAlwaysOn = input<boolean>(false);
  private tooltipElement: HTMLElement | null = null;
  private readonly TABLET_LANDSCAPE_WIDTH = 905;

  public readonly isMobile = signal(
    window.screen.width < this.TABLET_LANDSCAPE_WIDTH
  );

  public readonly isAlwaysOn = computed(() => {
    return this.sdTooltipAlwaysOn() && !!this.tooltipText();
  });

  constructor(private el: ElementRef, private renderer: Renderer2) {
    effect(() => {
      const tooltipText = this.tooltipText();
      if (
        this.tooltipElement &&
        this.tooltipElement.innerText !== tooltipText
      ) {
        this.tooltipElement.innerText = tooltipText;
      }
    });
    effect(() => {
      this.hideTooltip();

      if (this.isAlwaysOn()) {
        this.showTooltip();
      }
    });
  }

  ngAfterViewInit() {
    if (this.isAlwaysOn()) {
      this.showTooltip();
    }
  }

  ngOnDestroy() {
    this.hideTooltip();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (
      !this.tooltipElement &&
      !this.isMobile() &&
      this.tooltipText() &&
      !this.isAlwaysOn()
    ) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.isAlwaysOn()) {
      this.hideTooltip();
    }
  }

  private showTooltip() {
    if (this.tooltipElement) {
      return;
    }

    this.tooltipElement = this.renderer.createElement('span') as HTMLElement;
    this.tooltipElement.innerText = this.tooltipText();

    // Add classes to the tooltip element
    const classes = [
      'bg-neutral-700',
      'text-shades-white',
      'typo-p3',
      'tablet-landscape:typo-caption',
      'font-semibold',
      'py-1',
      'px-2',
      'rounded',
      'max-w-70',
      'whitespace-normal',
      'text-center',
      'block',
      'opacity-0',
      'absolute',
    ];

    classes.forEach((className) => {
      this.renderer.addClass(this.tooltipElement, className);
    });

    this.renderer.setStyle(this.tooltipElement, 'z-index', '1000');
    this.renderer.appendChild(document.body, this.tooltipElement);

    requestAnimationFrame(() => {
      if (!this.tooltipElement) return;

      // Get the position of the host element and the tooltip element
      const hostPos = (
        this.el.nativeElement as HTMLElement
      ).getBoundingClientRect();
      const tooltipPos = this.tooltipElement.getBoundingClientRect();
      const spacing = 8;

      let top: number;
      let left: number;

      // Calculate the position of the tooltip based on the direction
      switch (this.sdTooltipDirection()) {
        case 'right':
          top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
          left = hostPos.right + spacing * 2;
          break;
        case 'top':
        default:
          top = hostPos.top - tooltipPos.height - spacing;
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          break;
      }

      const viewportWidth = window.innerWidth;
      const minSpacing = 8;

      // Prevent tooltip from going out of the viewport to the right
      if (left + tooltipPos.width > viewportWidth - minSpacing) {
        left = viewportWidth - tooltipPos.width - minSpacing;
      }

      // Prevent tooltip from going out of the viewport to the left
      if (left < minSpacing) {
        left = minSpacing;
      }

      // Add scroll to position
      top += window.scrollY;
      left += window.scrollX;

      // Set the position of the tooltip
      this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
      this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
      this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
      this.renderer.setStyle(
        this.tooltipElement,
        'transition',
        'opacity 150ms ease-in-out'
      );
    });
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
