import { Directive, input } from '@angular/core';

import { AssistantIconsType } from './assistants';
import { BaseIconDirective } from './base-icon.directive';

@Directive({
  selector: 'svg[sdUseAssistantIcon]',
  standalone: true,
})
export class UseAssistantIconDirective extends BaseIconDirective {
  public readonly sdUseAssistantIcon = input.required<AssistantIconsType>();
  readonly BASE_PATH = '/assets/assistants.sprites.svg';

  public get icon() {
    return this.sdUseAssistantIcon();
  }
}
