export enum AssistantIconsEnum {
  'email' = 'email',
  'email-filled' = 'email-filled',
  'convention-filled' = 'convention-filled',
  'convention' = 'convention',
  'summary' = 'summary',
  'summary-filled' = 'summary-filled',
  'note' = 'note',
  'note-filled' = 'note-filled',
  'voice' = 'voice',
  'voice-filled' = 'voice-filled',
  'translate' = 'translate',
  'translate-filled' = 'translate-filled',
  'text-filled' = 'text-filled',
  'text' = 'text',
  'daily-recap-filled' = 'daily-recap-filled',
  'daily-recap' = 'daily-recap',
  'presentation-filled' = 'presentation-filled',
  'presentation' = 'presentation',
  'prompt' = 'prompt',
  'prompt-filled' = 'prompt-filled',
  'squadbox-guide' = 'squadbox-guide',
  'squadbox-guide-filled' = 'squadbox-guide-filled',
  'idea' = 'idea',
  'idea-filled' = 'idea-filled',
  'feedback' = 'feedback',
  'feedback-filled' = 'feedback-filled',
}

export type AssistantIconsType = `${AssistantIconsEnum}`;
export const AssistantIconsList = Object.values(AssistantIconsEnum);
