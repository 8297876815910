import { NgClass } from '@angular/common';
import { Component, forwardRef, input, output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'sd-textarea',
  imports: [NgClass, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
  styles: [],
  template: `
    <div class="flex flex-col gap-2 group">
      <label
        [for]="id"
        class="typo-caption font-semibold leading-4 h-5 self-start px-1 py-0.5"
        [ngClass]="{
          'text-neutral-400': isDisabled,
          'text-neutral-700 group-focus-within:text-primary-400': !isDisabled,
        }"
      >
        {{ label() }}
      </label>
      <textarea
        [id]="id"
        class="
          border border-neutral-200
          focus:border-primary-500 focus:outline-none
          caret-primary-700
          rounded-lg
          px-4 py-3
          min-h-18
          typo-p3
        "
        [ngClass]="{
          'text-neutral-400 hover:border-neutral-200 bg-transparency-white-32':
            isDisabled,
          'hover:border-primary-300': !isDisabled
        }"
        [placeholder]="placeholder()"
        [disabled]="isDisabled"
        [value]="value"
        (input)="onTextareaChange($event)"
        [attr.maxlength]="limit() > 0 ? limit() : null"
      ></textarea>
      @if (limit() > 0 || supportingText()) {
      <div
        class="flex flex-row justify-between *:typo-caption *:text-neutral-700 *:leading-4"
      >
        @if (supportingText()) {
        <span>{{ supportingText() }}</span>
        } @if (limit() > 0) {
        <span [class.ml-auto]="!supportingText()"
          >{{ value.length }}/{{ limit() }}</span
        >
        }
      </div>
      }
    </div>
  `,
})
export class TextareaComponent implements ControlValueAccessor {
  public readonly label = input<string>('Default Label');
  public readonly placeholder = input<string>('Default Placeholder');
  public readonly limit = input<number>(0);
  public readonly supportingText = input<string>('');

  public readonly valueChanged = output<string>();

  value = '';
  isDisabled = false;
  id = Math.random();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // Method called when the value of the parent form changes
  writeValue(value: string): void {
    this.value = value;
  }

  // Stores a function to be called when the textarea value changes
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  // Stores a function to be called when the textarea is touched
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Updates the disabled status of the field
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Manages the textarea event and notifies the parent of value changes
  onTextareaChange(event: Event): void {
    const textareaElement = event.target as HTMLTextAreaElement;
    this.value =
      this.limit() > 0
        ? textareaElement.value.slice(0, this.limit())
        : textareaElement.value;
    this.onChange(this.value);
    this.valueChanged.emit(this.value);
  }
}
