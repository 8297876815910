import { computed, Directive, input } from '@angular/core';
import { cva, VariantProps } from 'class-variance-authority';
import * as fromHelpers from '../../helpers';

export enum SpinnerSizeVariant {
  sm = 'sm',
  md = 'md',
}

export const spinnerVariants = cva(
  fromHelpers.cn(
    'inline-block rounded-full',
    'animate-spin',
    'border-t-primary-400',
    'border-r-primary-400/85',
    'border-b-primary-400/75',
    'border-l-primary-500/10'
  ),
  {
    variants: {
      size: {
        [`${SpinnerSizeVariant.sm}`]: 'size-4 border-[3px]',
        [`${SpinnerSizeVariant.md}`]: 'size-8 border-[4px]',
      },
    },
    defaultVariants: {
      size: SpinnerSizeVariant.sm,
    },
  }
);
export type SpinnerVariants = VariantProps<typeof spinnerVariants>;

@Directive({
  selector: '[sdSpinner]',
  standalone: true,
  host: {
    '[class]': 'className()',
    role: 'status',
  },
})
export class SpinnerDirective {
  readonly size = input<SpinnerVariants['size']>();

  protected readonly className = computed(() =>
    fromHelpers.cn(spinnerVariants({ size: this.size() }))
  );
}
