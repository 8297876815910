export enum NavigationIconsEnum {
  'report-comment-filled' = 'report-comment-filled',
  'document-filled' = 'document-filled',
  'document' = 'document',
  'kanban-filled' = 'kanban-filled',
  'kanban' = 'kanban',
  'dashboard-filled' = 'dashboard-filled',
  'dashboard' = 'dashboard',
  'bell-filled' = 'bell-filled',
  'bell' = 'bell',
  'home-filled' = 'home-filled',
  'home' = 'home',
  'logout' = 'logout',
  'sidebar' = 'sidebar',
  'new-chat' = 'new-chat',
}

export type NavigationIconsType = `${NavigationIconsEnum}`;
export const NavigationIconsList = Object.values(NavigationIconsEnum);
