import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

export interface ItemNavigation {
  id: number;
  selected: boolean;
  title: string;
  date: DateTime;
}

export type CategoryName = string;
export type CategoryNameTranslation = string;

export const getCategoryName = (itemDate: DateTime): CategoryName => {
  const diff = DateTime.local().startOf('day').diff(itemDate.startOf('day'));
  const days = Math.floor(diff.as('days'));
  const weeks = Math.floor(diff.as('weeks'));
  const months = Math.floor(diff.as('months'));
  const years = Math.floor(diff.as('years'));

  if (days <= 0) return 'today';
  if (days === 1) return 'yesterday';
  if (weeks < 1) return `${days} days ago`;
  if (weeks === 1) return '1 week ago';
  if (months < 1) return `${weeks} weeks ago`;
  if (months === 1) return '1 month ago';
  if (years < 1) return `${months} months ago`;
  if (years === 1) return '1 year ago';

  return `${years} years ago`;
};

export const getCategories = (
  items: ItemNavigation[]
): Record<string, ItemNavigation[]> => {
  return items.reduce(
    (categories, item) => {
      const categoryName = getCategoryName(item.date);

      if (!categories[categoryName]) {
        categories[categoryName] = [];
      }
      categories[categoryName].push(item);

      return categories;
    },
    { today: [] } as Record<string, ItemNavigation[]>
  );
};

export const getCategoriesKeys = (
  categories: Record<string, ItemNavigation[]>
): CategoryName[] => {
  return Object.keys(categories).filter(
    (categoryName) => categories[categoryName].length > 0
  );
};

@Injectable()
export class ItemsNavigationHelper {
  private readonly translate = inject(TranslateService);

  public getCategoryNameTranslation(
    category: CategoryName
  ): CategoryNameTranslation {
    const translationKeys = {
      today: 'NAVIGATION_ITEMS.TODAY',
      yesterday: 'NAVIGATION_ITEMS.YESTERDAY',
      'days ago': 'NAVIGATION_ITEMS.X_DAYS_AGO',
      'week ago': 'NAVIGATION_ITEMS.1_WEEK_AGO',
      'weeks ago': 'NAVIGATION_ITEMS.X_WEEKS_AGO',
      'month ago': 'NAVIGATION_ITEMS.1_MONTH_AGO',
      'months ago': 'NAVIGATION_ITEMS.X_MONTHS_AGO',
      'year ago': 'NAVIGATION_ITEMS.1_YEAR_AGO',
      'years ago': 'NAVIGATION_ITEMS.X_YEARS_AGO',
    };

    const [key, translationKey] =
      Object.entries(translationKeys).find(([k]) => category.includes(k)) ?? [];

    if (key && translationKey) {
      const value = category.replace(key, '').trim();
      const param = key.split(' ')[0];
      return this.translate.instant(translationKey, {
        [param]: value || 1, // Handle singular cases where value might be empty
      }) as string;
    }

    return category;
  }
}
